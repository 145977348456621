<template>
  <b-container class="dashboad bg-light page-wrapper">
    <b-row class="main-content-wrapper" no-gutters>
      <b-col>
        <h1>Tink Link</h1>
        <a href="https://link.tink.com/1.0/authorize/?client_id=ee92c099fd2e43b99cb9737de6ebab22&redirect_uri=http%3A%2F%2Flocalhost%3A8080%2Fsandbox%2Ftink-link&scope=accounts:read,investments:read,transactions:read,user:read,credentials:read,identity:read,statistics:read&market=SE&locale=sv_SE&test=false&input_username=198109064033">Link</a>
        <!--<iframe
          src="https://link.tink.com/1.0/authorize/?client_id=ee92c099fd2e43b99cb9737de6ebab22&redirect_uri=https%3A%2F%2Fconsole.tink.com%2Fcallback&scope=accounts:read,investments:read,transactions:read,user:read,credentials:read,identity:read,statistics:read&market=SE&locale=sv_SE"
          style="width:100%;height:800px;"
        >
      </iframe>-->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'TinkLink.vue'
}
</script>

<style scoped>

</style>
